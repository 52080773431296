import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./services/WebService";
import Notifications from "@kyvg/vue3-notification";
import helperService from "./services/HelperService";
import sharedService from "./services/ShareService";
import vue3StarRatings from "vue3-star-ratings";
import Dropdown from "vue-simple-search-dropdown";
import "./services/ValidationRules";
import "../public/sass/main.scss";
import store from "./services/StoreService";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);
library.add(fas);
// app.filter("titleCase", function (value) {
//   if (!value) {
//     return "";
//   } else {
//     var str = "";
//     var arr = value.split("_");
//     for (var i = 0; i < arr.length; i++) {
//       if (i == 0) {
//         str =
//           arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
//       } else {
//         str =
//           " " +
//           arr[i].charAt(0).toUpperCase() +
//           arr[i].substring(1).toLowerCase();
//       }
//     }
//     return str;
//   }
// });
import mitt from "mitt";
const emitter = mitt();
app.component("vue3-star-ratings", vue3StarRatings);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(Notifications);
app.mount("#app");
app.use(store);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$sharedService = sharedService;
app.use(Dropdown);
